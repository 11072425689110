<template>
  <div>
    <v-form ref="form" class="provider-form">
      <label class="text-field-label">Event</label>
      <v-select
        v-model="data.event_id"
        :items="events"
        label="Select Event"
        item-text="name"
        item-value="id"
        :error-messages="data.$getError('event_id')"
        @input="data.$clearError('event_id')"
      ></v-select>

      <label class="text-field-label">Services</label>

      <v-checkbox
        v-for="service in services"
        :key="service.id"
        v-model="data.services"
        :label="service.name"
        :value="service.id"
      ></v-checkbox>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Api from '@/services/api'
import { mdiClose } from '@mdi/js'

export default {
  props: {
    data: {
      required: true
    }
  },
  data() {
    return {
      icons: {
        close: mdiClose
      },
      events: [],
      services: []
    }
  },
  computed: {
    ...mapState({
      provider: state => state.provider.details
    })
  },
  mounted() {
    Api.get('event', { params: { per_page: 100 } }).then(response => {
      this.events = response.data.data
    })
    Api.get(`admin/providers/${this.provider.id}/services`, {
      params: { per_page: 100 }
    }).then(response => {
      this.services = response.data.data
    })
  },
  methods: {
    reset() {
      this.data.$reset()
    }
  }
}
</script>
