<template>
  <div>
    <div
      class="mt-12 mx-4"
      id="provider-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-btn class="primary" elevation="2" @click="showForm = true">
        <span>
          Add new listing
        </span>
      </v-btn>

      <v-simple-table>
        <thead>
          <tr>
            <th width="30%" class="text-left pa-0">Name</th>
            <th width="30%" class="text-left">Services</th>
            <th width="30%" class="text-left">Service Types</th>
            <th width="10%" class="text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="listing in listings" :key="listing.id">
            <td>{{ listing.event.name }}</td>
            <td>{{ listing.services.map(s => s.name).join(', ') }}</td>
            <td>{{ getServiceTypes(listing.services) }}</td>
            <td class="d-flex">
              <v-btn
                class="primary mr-1"
                small
                elevation="2"
                @click="initEdit(listing)"
              >
                Edit
              </v-btn>
              <v-btn
                class="primary"
                small
                elevation="2"
                @click="initDelete(listing)"
              >
                Delete
              </v-btn>
            </td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(listings.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>

    <form-modal
      v-model="showForm"
      :title="selected ? 'Edit Listing' : 'Add Provider'"
      :max-width="600"
    >
      <template #form>
        <listing-form :data="form" ref="form" />
      </template>

      <template #submit-action>
        <v-btn text @click="showForm = false">Cancel</v-btn>
        <v-btn text :loading="submiting" @click="submitListing">{{
          selected ? 'Update' : 'Submit'
        }}</v-btn>
      </template>
    </form-modal>

    <ConfirmModal
      v-model="showDeleteModal"
      title="Delete Listing"
      message="Are you sure you want to delete this listing?"
      @cancel="showDeleteModal = false"
      @confirm="confirmDelete"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import FormModal from '@/components/modals/FormModal.vue'
import ConfirmModal from '@/components/modals/ConfirmModal'
import ListingForm from './ListingForm.vue'
import Api from '@/services/api'

import infiniteScroll from 'vue-infinite-scroll'
import Form from '@/utils/form'

export default {
  name: 'ProviderService',
  mixins: [ControlsMixin],
  directives: {
    infiniteScroll
  },
  components: {
    FormModal,
    ListingForm,
    ConfirmModal
  },
  data() {
    return {
      loading: false,
      submiting: false,
      showSearch: false,
      filter: {
        search: ''
      },
      showForm: false,
      sortOrder: true,
      defaultFilter: 'created_at',
      form: new Form({
        event_id: '',
        services: []
      }),
      selected: null,
      showDeleteModal: false
    }
  },
  computed: {
    ...mapState({
      provider: state => state.provider.details,
      listings: state => state.listing.list,
      listMeta: state => state.listing.listMeta
    }),
    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    }
  },
  mounted() {
    this.clearListings()
    this.fetchListings(1)
  },
  methods: {
    ...mapActions({
      getListings: 'listing/getListings'
    }),
    ...mapMutations({
      clearListings: 'listing/clearListingList'
    }),
    getServiceTypes(services = []) {
      let types = []

      services.forEach(service => {
        service.categories.forEach(category => {
          if (!types.includes(category.name)) {
            types.push(category.name)
          }
        })
      })

      return types.join(', ')
    },
    async fetchListings(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getListings({ params, provider: this.provider })
      this.loading = false
    },
    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchListings(this.listMeta.current_page + 1)
      }
    },
    filterProviders(filterValue) {
      this.clearListings()
      this.fetchListings(null, filterValue)
    },
    initEdit(listing) {
      this.selected = listing
      this.form.event_id = listing.event_id
      this.form.services = listing.services.map(s => s.id)
      this.showForm = true
    },
    initDelete(listing) {
      this.selected = listing
      this.showDeleteModal = true
    },
    submitListing() {
      this.submiting = true

      const method = this.selected ? 'put' : 'post'
      const url = this.selected
        ? `/admin/providers/${this.provider.id}/listings/${this.selected.id}`
        : `/admin/providers/${this.provider.id}/listings`

      Api[method](url, {
        event_id: this.form.event_id,
        services: this.form.services
      })
        .then(() => {
          this.clearListings()
          this.showForm = false
          this.$refs.form.reset()
          this.selected = null
          this.fetchListings()
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.form.$setErrors(error.response.data.errors)
          }
        })
        .finally(() => (this.submiting = false))
    },
    confirmDelete() {
      Api.delete(
        `/admin/providers/${this.provider.id}/listings/${this.selected.id}`
      ).then(() => {
        this.clearListings()
        this.selected = null
        this.showDeleteModal = false
        this.fetchListings()
      })
    }
  }
}
</script>
