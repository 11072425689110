<template>
  <v-dialog
    :value="value"
    @input="$emit('input')"
    :max-width="maxWidth"
    persistent
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <slot name="form" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="submit-action" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: {
      type: String,
      required: true
    },
    maxWidth: {
      type: Number,
      default: 200
    }
  }
}
</script>

<style></style>
